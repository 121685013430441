<template>
  <v-app>
    <router-view v-if="!loading" />

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "@/services/auth";
import AlertBar from "@/components/alertBar/AlertBar.vue";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("sign-out", this.signOut);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
    this.$root.$off("sign-out", this.signOut);
  },

  methods: {
    ...mapActions(["setUser", "setSignOut"]),

    async handleSession() {
      const token = this.$store.getters.accessToken;

      if (token) {
        try {
          const { data } = await loadSession();
          this.setUser(data);
        } catch {
          this.signOut();
        }
      }

      this.loading = false;
    },

    async signOut() {
      try {
        await this.setSignOut();

        if (this.$route.name !== "SignIn")
          this.$router.push({ name: "SignIn" });
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },
  },
};
</script>

<style lang="scss">
html {
  background: $background;
}
</style>
