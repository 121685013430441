<template>
  <div class="main-wrapper">
    <v-app-bar color="transparent" flat app>
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-spacer />

      <v-btn icon @click="signOut()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'background1'"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <v-card
          :class="$vuetify.breakpoint.lgAndUp ? 'ma-2 rounded-lg' : 'rounded-0'"
          color="background1"
          height="100%"
          flat
        >
          <div class="text-center my-4">
            <span class="font-italic text-h5 font-weight-black primary--text">
              Criptospace
            </span>
          </div>

          <div class="d-flex fill-height">
            <div
              class="secondary left-marker"
              :class="
                $vuetify.breakpoint.lgAndUp ? 'rounded-l-lg' : 'rounded-0'
              "
            />

            <div class="d-flex flex-column fill-width">
              <v-list class="py-0" color="transparent" dense nav>
                <router-link
                  v-for="(item, i) in routes"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.path"
                  :key="i"
                  custom
                >
                  <v-list-item
                    :href="href"
                    :class="{
                      background3: isActive,
                    }"
                    @click="navigate"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                      {{ item.label }}
                    </v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>
            </div>
          </div>
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Main",

  data() {
    return {
      drawer: true,
      routes: [
        {
          icon: "mdi-home",
          label: "Início",
          path: "/home",
        },
        {
          icon: "mdi-finance",
          label: "Negociações",
          path: "/tradings",
        },
        {
          icon: "mdi-currency-btc",
          label: "Criptomoedas",
          path: "/currencies",
        },
      ],
    };
  },

  methods: {
    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
