import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../pages/main/Main.vue";
import Auth from "../pages/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: "home",
        name: "Home",
        alias: "",
        meta: { title: "Início", requiresAuth: true },
        component: () => import("@/pages/main/views/home/Home.vue"),
      },
      {
        path: "tradings",
        name: "Tradings",
        meta: { title: "Negociações", requiresAuth: true },
        component: () => import("@/pages/main/views/tradings/Tradings.vue"),
      },
      {
        path: "currencies",
        name: "Currencies",
        meta: { title: "Criptomoedas", requiresAuth: true },
        component: () => import("@/pages/main/views/currencies/Currencies.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        alias: "",
        meta: { title: "Autenticação", preventAuth: true },
        component: () => import("@/pages/auth/views/signIn/SignIn.vue"),
      },
      /* {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Registro", preventAuth: true },
        component: () => import("@/pages/auth/views/signUp/SignUp.vue"),
      }, */
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
