import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage,
  modules: ["accessToken", "refreshToken", "user"],
});

export default new Vuex.Store({
  state: {
    accessToken: "",
    refreshToken: "",
    user: null,
  },

  getters: {
    authenticated: ({ accessToken, refreshToken, user }) =>
      !!accessToken && !!refreshToken && !!user,
    accessToken: ({ accessToken }) => accessToken,
    refreshToken: ({ refreshToken }) => refreshToken,
    user: ({ user }) => user,
  },

  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },

    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setSignIn({ dispatch }, payload) {
      dispatch("setAccessToken", payload.accessToken);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setUser", payload.user);
    },

    setSignOut({ dispatch }) {
      dispatch("setAccessToken", "");
      dispatch("setRefreshToken", "");
      dispatch("setUser", null);
    },
  },

  modules: {},

  plugins: [vuexLocal.plugin],
});
