<template>
  <div class="auth-wrapper">
    <v-main class="fill-height">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col class="py-6" cols="12" sm="8" md="6" lg="5" xl="4">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="scss" src="./style.scss" scoped />
