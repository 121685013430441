import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#00ED5F",
        error: "#FF5252",
        $info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#2e3440",
        background1: "#3b4252",
        background2: "#434c5e",
        background3: "#4c566a",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
